import React from 'react'
import PropTypes from 'prop-types'

const TwoColGrid = (props) => {
  const { children, className } = props

  return (
    <div className={`sm:grid grid-cols-2 gap-x-6 gap-y-10 space-y-10 sm:space-y-0 ${className}`}>
      {children}
    </div>
  )
}

TwoColGrid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string
  ]).isRequired,
  className: PropTypes.string,
}

TwoColGrid.defaultProps = {
  className: '',
}

export default TwoColGrid
